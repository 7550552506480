"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const game_1 = require("@games/loancalculator/src/game");
game_1.appModule.component('gameCarloancalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: game_1.LoancalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
game_1.appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('carloancalculator/');
    }]);
